import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import cn from './cn';
import en from './en';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      'zh-CN': { translation: cn },
      'zh-HK': { translation: cn },
      'zh-TW': { translation: cn }
    },
    lng: 'zh-CN', // 强制设置默认语言为中文
    fallbackLng: 'zh-CN',

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
