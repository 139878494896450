import * as actionTypes from './action-types-classfx';

export const reduxClassFXInitialState = {
  sceneType: null,
  mainTeacherVoice: null,
  minorTeacherVoice: null,
  classVoice: null,
  emailUrl: null
};

const reducerClassFX = (state = reduxClassFXInitialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CLASSFX_SCENETYPE:
      // 场景的改变，需要重置所有的已有参数
      return {
        ...state,
        sceneType: action.data,
        mainTeacherVoice: null,
        minorTeacherVoice: null,
        classVoice: null,
        emailUrl: null
      };
    case actionTypes.UPDATE_CLASSFX_MAINTEACHERVOICE:
      return {
        ...state,
        mainTeacherVoice: action.data
      };
    case actionTypes.UPDATE_CLASSFX_MINORTEACHERVOICE:
      return {
        ...state,
        minorTeacherVoice: action.data
      };
    case actionTypes.UPDATE_CLASSFX_CLASSVOICE:
      return {
        ...state,
        classVoice: action.data
      };
    case actionTypes.UPDATE_CLASSFX_EMAILURL:
      return {
        ...state,
        emailUrl: action.data
      };
    default:
      return state;
  }
};

export default reducerClassFX;
