import sha256 from 'sha256';
import _ from 'lodash';
import request from '@/lib/request';
import whichEnvironment from '@/common/utils/whichEnvironment';

const env = whichEnvironment();
let paasUrl = '';
let loginUrl = '';
if (env === 'DEV' || env === 'CODING') {
  paasUrl = 'https://gateway-test-multi.facethink.com';
  loginUrl = '//ai-role.facethink.com';
} else if (env === 'TEST') {
  paasUrl = 'https://gateway-test-multi.facethink.com';
  loginUrl = '//ai-role.facethink.com';
} else if (env === 'RELEASE') {
  paasUrl = 'https://openai.100tal.com';
  loginUrl = '//ai-role.100tal.com';
} else {
  paasUrl = 'https://openai.100tal.com';
  loginUrl = '//ai-role.100tal.com';
}

export default {
  consoleLogin (data) {
    data.username = _.trim(data.username);
    data.password = sha256(_.trim(data.password));
    return request({
      method: 'POST',
      url: paasUrl + '/user/console/oauth/token',
      data
    });
  },
  // 神秀登录
  shenxiuLogin (data) {
    data.username = _.trim(data.username);
    data.password = sha256(_.trim(data.password));
    return request({
      method: 'POST',
      url: loginUrl + '/identity/user/login',
      data
    });
  },

  consoleRegisterMobile (dataArg) {
    const data = Object.assign({}, dataArg);
    data.password = sha256(_.trim(data.password));
    data.confirm_password = sha256(_.trim(data.confirm_password));
    return request({
      method: 'POST',
      url: paasUrl + '/user/console/user/register/mobile',
      data
    });
  },

  consoleRegister (dataArg) {
    const data = Object.assign({}, dataArg);
    data.password = sha256(_.trim(data.password));
    data.confirm_password = sha256(_.trim(data.confirm_password));
    return request({
      method: 'POST',
      url: paasUrl + '/user/console/user/register',
      data
    });
  },

  consoleLogout () {
    return request({
      method: 'POST',
      url: paasUrl + '/user/console/oauth/logout'
    });
  },

  fetchBackendPrivAndUserInfo () {
    return request({
      method: 'GET',
      url: paasUrl + '/user/privilege/accesstoken'
    });
  },

  fetchMobileVerifyCode (params) {
    return request({
      method: 'GET',
      url: paasUrl + '/user/sms/verifycode',
      params
    });
  },

  fetchImgVerifyCode () {
    return request({
      method: 'GET',
      url: paasUrl + '/user/verifycode'
    });
  },

  fetchVerifyCode () {
    return this.fetchImgVerifyCode();
  },

  consoleResetPassword (data) {
    data.password = sha256(_.trim(data.password));
    return request({
      method: 'POST',
      url: paasUrl + '/user/console/user/forgetpassword',
      data
    });
  },
  // 获取个人信息
  getUserDetail () {
    return request({
      method: 'GET',
      url: paasUrl + '/user/console/user/detail'
    });
  },
  // 获取小美用户详情
  getMathUserDetail () {
    return request({
      method: 'GET',
      url: paasUrl + '/user/console/user/mathgpt/detail',
      fromStorage: true
    });
  },
  // 小美用户注册
  registMathgpt (data) {
    data.password = sha256(_.trim(data.password));
    data.confirm_password = sha256(_.trim(data.confirm_password));
    return request({
      method: 'POST',
      url: paasUrl + '/user/console/user/mathgpt/register/mobile',
      data
    });
  },
  // 水印获取随机字符串
  getRandomString (params) {
    return request({
      method: 'get',
      params,
      url: paasUrl + '/user/console/user/mathgpt/detail'
    });
  }
};
