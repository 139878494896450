import navigatorData from './navigator.json';

const list = navigatorData.ability.list;
const res = [];

const initAbilityData = (data) => {
  data.forEach(item => {
    if (item.children) {
      initAbilityData(item.children);
    } else {
      res.push(item);
    }
  });
};
initAbilityData(list);
export default res;


