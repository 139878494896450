import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunkMiddleware from 'redux-thunk';
import reducer from './reducer';

const prd = process.env.NODE_ENV === 'production';

const initializeStore = (initialState) => {
  if (prd) {
    return createStore(reducer, initialState, applyMiddleware(thunkMiddleware));
  }
  return createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(thunkMiddleware))
  );
};

export default initializeStore;
