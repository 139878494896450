const metaInfo = {
  keyWords: [
    'ai智能教育平台',
    '好未来',
    'AI开放平台',
    '教育',
    '人工智能',
    '语音',
    '语音技术',
    '图像',
    '图像技术',
    'NLp',
    '自然语言',
    '视频技术',
    '深度学习',
    '机器学习',
    '教育场景',
    '解决方案',
    'AI'
  ],
  description:
    '好未来AI开放平台，依托多年教育行业经验与海量行业数据优势，深耕教育领域人工智能技术创新，为广大教育行业伙伴提供领先的AI能力与解决方案，助力教育智能化发展'
};
export default metaInfo;
