export default {
  loginBrandTop: '好未来自主研发',
  loginBrandBottom: '的数学领域大模型',
  welcome: '欢迎体验MathGPT，请登录',
  inputPhone: '请输入手机号',
  validPhone: '请输入有效的手机号',
  lang: 'cn',
  loginTitle: '欢迎体验MathGPT，请先登录',
  placeholderPhone: '请输入手机号',
  placeholderCode: '请填写短信验证码',
  getCodeBtn: '获取验证码',
  checkPhone: '请填写正确的手机号',
  checkCode: '请填写正确的验证码',
  checkPolicy: '请勾选下方协议',
  loginBtn: '登录',
  agreement1: '我已阅读并同意',
  agreement2: '《用户协议》',
  agreement3: '和',
  agreement4: '《隐私政策》',
  applyBrandContent:
    '请您完整详细填写注册申请表，这将有助于您更快完成您的体验申请，您填写的信息将用于体验申请审核，请确保您的联系信息真实有效，以便工作人员在需要时与您取得联系。',
  applyProgress1: '注册登录',
  applyProgress2: '申请中',
  applyProgress3: '审核',
  applyProgress4: '完成',
  applyTitle: 'MathGPT数学大模型体验申请',
  applyToast: '您填写的信息将用于账号注册以及体验申请审核',
  changeType: '选择类型',
  changePurpose: '选择用途',
  type1: '个人',
  type2: '企业',
  type1Purpose1: '数学解题',
  type1Purpose2: '辅导数学',
  type1Purpose3: '兴趣学习',
  type2Purpose1: '个性化教学支持',
  type2Purpose2: '定制化应用开发',
  type2Purpose3: '复杂问题求解',
  commonPurpose: '其他',
  placeholderName: '请输入姓名',
  placeholderCompany: '请输入真实的企业名称',
  placeholderPosition: '请输入真实的岗位',
  placeholderEmail: '请输入邮箱',
  applyBtn: '提交申请',
  checkEmail: '邮箱输入有误',
  checkApplyName: '只支持中英文，最长不能超过14个字',
  checkCommonName: '只支持中英文，最长不能超过40个字',
  auditTitle1: '您已加入等待体验中，',
  auditTitle2: '加入成功，我们会以短信形式通知您～',
  auditFooter1: '继续了解，',
  auditFooter2: '返回官网',
  homeBtn: '开始新会话',
  homeDateToday: '今日',
  homeDateYesterday: '昨日',
  homePrevious7: '近7日',
  homePrevious30: '近30日',
  month: '月',
  year: '年',
  footerSetting: '设置',
  footerOut: '退出账号',
  modalAccount: '账号管理',
  modalUserDelete: '账号注销',
  modalDeleteText:
    '如果您不再使用此账号，可以将其注销。账号成功注销后，其下所有服务、数据及隐私信息将会被删除并将无法恢复',
  modalDeleteBtn: '去注销',
  verifyModalTitle: '验证身份',
  verifyModalText1: '为确保账号',
  verifyModalText2: '是你本人的操作，',
  verifyModalText3: '请进行手机号验证',
  verifyModalBtn1: '取消',
  verifyModalBtn2: '立即验证',
  toVerifyModalTitle: '确定要注销账号吗？',
  toVerifyModalText:
    '账号成功注销后，旗下所有服务、数据及隐私信息将会被删除并无法恢复',
  toVerifyModalBtn: '确定',
  quitModalTitle: '提示',
  quitModalText: '您确认要退出登录？',
  primaryExample: '小学示例',
  middleExample: '初中示例',
  highExample: '高中示例',
  quesOrigin: 'quesOrigin',
  ques: 'ques',
  editorInput: '请输入内容，换行可通过shift+回车',
  editorEquations: '公式编辑',
  editorImg: '请上传带有数字或文字的图片',
  termsService: '用户协议',
  privacyPolicy: '隐私政策',
  feedback: '反馈邮箱',
  toastLogin: '登录成功！',
  toastLogOut: '退出成功！',
  toastDelete: '注销成功！',
  toastApply: '提交成功！',
  toastCreate: '创建成功！',
  toastDel: '删除成功！',
  toastUpdate: '修改成功！',
  toastCopy: '复制成功！',
  toastLike: '点赞成功！',
  toastStepOn: '点踩成功！',
  toastBack: '反馈成功！',
  feedbackTitle: '反馈',
  feedbackBtn: '确认',
  feedbackText: '请输入反馈内容，如果您认为有更好的的回答可以告诉我们',
  startChat: '开始新会话，',
  startChatEnd: '换个话题聊聊吧',
  ban24:
    '作为一个人工智能数学大模型，我目前只能回答与数学相关的问题，请24小时后再向我提问。',
  banForever:
    '作为一个人工智能数学大模型，我目前只能回答与数学相关的问题。若您需要继续使用，请联系客服人员：MathGPT@tal.com。',
  violationText: '很抱歉，我还没有学习到关于这个话题的内容，无法提供相关信息。您可以选择其他问题，我将努力为您解答。',
  imgUpload: '请上传指定类型图片：',
  imgErrorSize: '图片尺寸不合格',
  imgErrorSize2: '上传图片大小不能超过2M',
  knowledge: '知识点',
  goodLuck: '随机来一题',
  luckErrorToast: '出了点小问题，无法获取题目，您可以稍后再试',
  primary: '小学',
  middle: '初中',
  high: '高中',
  popoverListItemQueTag: '题',
  mathanswering: '回复中',
  related: '作为一个人工智能数学大模型，我目前只能回答与数学相关的问题',
  chatException: '对话繁忙，请您稍后重试',
  timeout: '登录繁忙，请您稍后重试',
  loginExpired: '登录已过期',
  forbiddon403: '403 禁止访问页面，请查看其他页面',
  notFound: '404 没有找到相应内容，请查看其他页面',
  serviceException: '500 服务器异常，请稍后重试',
  requestErr: '502 请求失败，请稍后重试',
  otherErr: '服务器异常，请重新尝试',
  official: 'chinese',
  longToken: '抱歉，您的输入内容过长',
  system: '系统繁忙，请稍后重试',
  nextSession: '请等待上一个会话结束后再发下一个会话',
  overSend: '请在上一次会话结束后发送',
  errorLatex: '对话框中含有错误Latex文本，是否继续发送',
  noFindText: '没有识别到文字',
  imgNoIdentify: '图片无法识别，请重新上传',
  ocrText: 'OCR识别中...',
  imgPreview: '图片预览',
  invalidNet: '网络不可用',
  searchText: '查询不能为空',
  inputWarning: '输入不能为空',
  remind: '提醒',
  latexErr: '公式渲染似乎出了点问题，我们正在努力修复中',
  evaluate: '评价成功',
  analysis: '【分析】',
  detail: '【详解】',
  point: '【点睛】',
  hasNewchat: '当前已是最新会话',
  compareAnswer: '本次回答相较上次回答',
  better: '更好',
  worse: '更差',
  almost: '差不多',
  regenerate: '重新生成',
  stopGenerate: '停止生成',
  noRecommend: '暂无推荐',
  formula: '公式',
  current130B: '当前模型：130B',
  pleaseSay: '请说出你的问题',
  pleaseSay2: '请讲话...',
  unableRecord: '无法录音',
  noMicrophone: '无可用麦克风',
  soundToolow: '您的声音太小，请大声讲述您的内容～',
  recorderToast: '您已录制超过最大时长: 3分钟。马上为您分析数据~'
};
