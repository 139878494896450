function whichEnvironment() {
  if (typeof window === 'undefined') {
    return false;
  }
  /* eslint-disable indent */
  switch (window.location.host) {
    case 'ai.100tal.com':
      return 'RELEASE';
    case 'ai-test.facethink.com':
      return 'TEST';
    case 'ai-dev.facethink.com':
      return 'DEV';
    default:
      return 'CODING';
  }
  /* eslint-enable indent */
}

export default whichEnvironment;
