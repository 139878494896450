import React from 'react';
import queryString from 'query-string';
import less from './Navigator.less';
import headerLogo from '../../images/header-logo-shenxiu.png';

function gotoHomePage() {
  const { from } = queryString.parse(window.location.search);
  if (from === 'shenxiu') {
    window.location.href = '//fe-test.facethink.com/shenxiu';
  } else {
    window.location.href = '/';
  }
}

class NavigatorOnlyLogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from: 'loading',
    };
  }

  componentDidMount() {
    const { from } = queryString.parse(window.location.search);
    this.setState({ from });
  }

  render() {
    let Logo = '';
    if (this.state.from === 'loading') {
      Logo = '';
    } else if (this.state.from === 'shenxiu') { // 神秀登录页面定制
      Logo = (<img
        className={less.logo}
        src={headerLogo}
        alt="tal logo"
      />);
    } else {
      Logo = (<img
        className={less.logo}
        src="https://openplantform.oss-cn-beijing.aliyuncs.com/logo/logo%403x-1-navigator.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=4739852613&Signature=fkQuH6jrDNm4QnqqTwHmW4%2FGgjI%3D"
        alt="shenxiu logo"
      />);
    }
    return (
      <div
        className={`${less.container} ${less.active}`}
        onMouseLeave={this.setNavNotHover}
      >
        <div
          className={less.leftPart}
          onMouseEnter={this.setNavHover}
        >
          <div
            className={less.logoBox}
            onClick={gotoHomePage}
          >
            {Logo}
          </div>
        </div>
      </div>
    );
  }
}

export default NavigatorOnlyLogo;
