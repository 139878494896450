import * as actionTypes from './action-types-enCorrect';

export const enCorrectInitialState = {
  // 基本数据
  all_data: {},
  // 纠错
  errorWords: [],
  // 建议数据
  suggestionWord: [],
  // levelSource: 0,
  // 连词数据
  conjunctionList: [],
  // 词组数据
  phrasesList: [],
  // 从句
  clauseList: [],
  // 整体结果数据
  overall_result: {}
};

const reducerEnCorrect = (state = enCorrectInitialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ALLDATA:
      return {
        ...state,
        all_data: action.data
      };
    case actionTypes.UPDATE_OVERALL:
      return {
        ...state,
        overall_result: action.data
      };
    case actionTypes.UPDATE_ERROR:
      return {
        ...state,
        errorWords: action.data
      };
    case actionTypes.UPDATE_SUGGESTION:
      return {
        ...state,
        suggestionWord: action.data
      };
    case actionTypes.UPDATE_CONJUNCTION:
      return {
        ...state,
        conjunctionList: action.data
      };
    case actionTypes.UPDATE_PHRASES:
      return {
        ...state,
        phrasesList: action.data
      };
    case actionTypes.UPDATE_CLAUSE:
      return {
        ...state,
        clauseList: action.data
      };
    default:
      return state;
  }
};

export default reducerEnCorrect;
