import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import classnames from 'classnames';
import Navigator from '../common/Navigator';
import NavigatorOnlyLogo from '../common/NavigatorOnlyLogo';
import TalFooter from '../common/Footer';
import SideBar from '../common/Sidebar';
import { StyleLayout as LayoutWrapper } from '../../styled-components/global-css';
import solutionList from '@/data/expand_solution.json';
import productionList from '@/data/openAbility.json';
import account from '@/common/account/index';
import style from './Layout.less';

const TalLayout = ({ children, router }) => {
  // 在特殊url中需要隐藏模块
  const [specialUrlIsHideModule, setSpecialUrlIsHideModule] = useState(true);
  const isFullWidth = router.route.includes('MathGPT');
  const hasFooter =
    router.route !== '/PreviewModel' &&
    router.route !== '/DeploymentModel' &&
    router.route !== '/MathGPT' &&
    router.route !== '/activity/nationalday_1001' &&
    router.route !== '/share-gpt' &&
    router.route !== '/contact-us' &&
    router.route !== '/virtual-assistant' &&
    router.route !== '/evaluate-result' &&
    router.route !== '/dataset-privacy' &&
    router.route !== '/dataset-auth' &&
    router.route !== '/speaker-separation' &&
    router.route !== '/photo-search' &&
    router.route !== '/video_test' &&
    router.route !== '/video-Interpretation' &&
    router.route !== '/video_test' &&
    router.route !== '/androidDown/intelligentExercise' &&
    router.route !== '/evaluation-result-list' &&
    router.route !== '/androidDown/suoyiran' &&
    router.route !== '/androidDown/liveAssistant' &&
    router.route !== '/androidDown/workCorrect' &&
    router.route.indexOf('/account/') < 0;
  const hasNavigator =
    router.route !== '/PreviewModel' &&
    router.route !== '/DeploymentModel' &&
    router.route !== '/MathGPT' &&
    router.route !== '/share-gpt' &&
    router.route !== '/speaker-separation' &&
    router.route !== '/androidDown/intelligentExercise' &&
    router.route !== '/androidDown/suoyiran' &&
    router.route !== '/androidDown/liveAssistant' &&
    router.route !== '/androidDown/workCorrect' &&
    router.route !== '/account/login' &&
    router.route !== '/video_test' &&
    router.route !== '/photo-search' &&
    router.route !== '/video_test' &&
    router.route !== '/video-Interpretation' &&
    router.route !== '/MathGPTResult';
  // 虚拟助教在iframe中隐藏haeder和分享
  useEffect(() => {
    if (process.title === 'browser') {
      if (router.route === '/virtual-assistant') {
        setSpecialUrlIsHideModule(window.self !== window.top);
        console.log('specialUrlIsHideModule', specialUrlIsHideModule);
      } else if (
        router.route === '/MathGPT' ||
        router.route === '/activity/nationalday_1001' ||
        router.route === '/share-gpt' ||
        router.route === '/MathGPTResult' ||
        router.route === '/DeploymentModel' ||
        router.route === '/PreviewModel'
      ) {
        setSpecialUrlIsHideModule(true);
      } else {
        setSpecialUrlIsHideModule(false);
      }
    }
  }, [router.route, specialUrlIsHideModule]);

  const isNavigatorOnlyLogo =
    router.route === '/account/register' ||
    router.route === '/account/forget-password' ||
    router.route === '/account/success';
  let isNavigatorAlwaysActive = true;
  if (
    router.route == '/' ||
    (router.route === '/solution' && solutionList[router.query.name]) ||
    (router.route === '/product' &&
      productionList.find((p) => p.name === router.query.name)) ||
    router.route === '/news-info' ||
    router.route === '/dataset' ||
    router.route === '/industrialUnion/union' ||
    router.route === '/developService/market'
  ) {
    isNavigatorAlwaysActive = false;
  }
  // fix：抽离导航组件的登录判断，避免导航组件的更新重复请求该接口
  const [hasLoggedin, setHasLoggedin] = useState('');
  const checkHasLogin = useCallback(() => {
    if (
      hasNavigator &&
      !isNavigatorOnlyLogo &&
      router.route !== '/virtual-assistant'
    ) {
      account.getUserDetail().then(
        (res) => {
          let bool = '';
          if (res.code === 2000000) {
            bool = true;
          } else {
            bool = false;
          }
          setHasLoggedin(bool);
        },
        (err) => {
          setHasLoggedin(false);
        }
      );
    }
  }, [hasNavigator, isNavigatorOnlyLogo, router.route]);
  useEffect(() => {
    checkHasLogin();
  }, [router.route]);
  return (
    <LayoutWrapper
      className={classnames({
        [style['full-width']]: isFullWidth
      })}
    >
      {hasNavigator && isNavigatorOnlyLogo && !specialUrlIsHideModule && (
        <NavigatorOnlyLogo />
      )}
      {hasNavigator && !isNavigatorOnlyLogo && !specialUrlIsHideModule && (
        <Navigator
          isNavigatorAlwaysActive={isNavigatorAlwaysActive}
          hasLoggedin={hasLoggedin}
          logoutFunc={() => {
            setHasLoggedin(false);
          }}
        />
      )}
      {!specialUrlIsHideModule && <SideBar />}
      {children}
      {hasFooter && <TalFooter />}
    </LayoutWrapper>
  );
};

TalLayout.propTypes = {
  children: PropTypes.node
};

// 后续会从redux中获取数据
export default connect(null, null)(withRouter(TalLayout));
