import * as actionTypes from './action-types-dolphin';

export const reduxDolphinInitialState = {
  AsrResult:
    '我最喜欢的动物是小狗。因为它活蹦乱跳的非常可爱。越加就有一只小狗。一只肥头大耳的勾。他每次现在我都会摇着尾巴非常开心地向我走来。并且呢它为王汪汪地叫。然后再跟我打招呼一样。我喜欢摸它。它的毛。那么三。嗯怎么说又软又温暖。答有一点四X。特别厉害。就是它那个它它。它它可以结着我。中的小球。嗯可太棒了嗯。嗯我希望我的妈妈也可以进位小一只就是像爷爷在这样的？那么非常可爱的小狗。这样我可以每天不管是早上。还是晚上都能和他在一起了。因为我这个太喜欢小狗了。就是。', // asr识别文本
  FileLen: 68.4432, // 文件时长
  ContentProbability: 1, // 内容相关置信度
  EmotionProbability: 0.1187, // 情感置信度
  FluentProbability: 0.382, // 流利置信度
  SlicefluProbability: [0.5545, 0.9446, 0.0333, 0.0187, 0.7523, 0.077], // 流利置信度片段值
  LogicProbability: 0.6189, // 逻辑置信度
  InterrogativeNum: 1, // 疑问句句数
  PetPhrase: {
    那个: 1,
    就是: 3,
    这个: 1
  }, // 口头禅
  SpeedByVoice: 3.7371, // 语速
  TotalCharNum: 204, // asr识别结果字数
  TotalSentNum: 22, // 总句数
  VoiceLen: 54.64, // 说话时长
  LongSent: 7, // 长难句
  Duplicates: {
    count: 18,
    list: [
      {
        start_time: 3990,
        end_time: 4410
      },
      {
        start_time: 7740,
        end_time: 8370
      },
      {
        start_time: 10170,
        end_time: 10760
      },
      {
        start_time: 12270,
        end_time: 13250
      },
      {
        start_time: 17460,
        end_time: 18150
      },
      {
        start_time: 20990,
        end_time: 21580
      },
      {
        start_time: 23420,
        end_time: 24430
      },
      {
        start_time: 25540,
        end_time: 25980
      },
      {
        start_time: 26630,
        end_time: 27060
      },
      {
        start_time: 27560,
        end_time: 28460
      },
      {
        start_time: 31490,
        end_time: 32030
      },
      {
        start_time: 35290,
        end_time: 36100
      },
      {
        start_time: 37440,
        end_time: 38080
      },
      {
        start_time: 40470,
        end_time: 40990
      },
      {
        start_time: 42850,
        end_time: 43270
      },
      {
        start_time: 45550,
        end_time: 46200
      },
      {
        start_time: 49740,
        end_time: 50470
      },
      {
        start_time: 55860,
        end_time: 56420
      },
      {
        start_time: 58810,
        end_time: 59650
      },
      {
        start_time: 62850,
        end_time: 63810
      },
      {
        start_time: 66360,
        end_time: 67390
      },
      {
        start_time: 70390,
        end_time: 74310
      }
    ],
    detail: {
      less_1: 15,
      between_1_2: 2,
      between_2_3: 0,
      more_3: 1
    }
  },
  PunchLine: {
    // 妙语连珠
    start_time: 1880,
    end_time: 12270,
    text: '我最喜欢的动物是小狗，因为它活蹦乱跳的非常可爱。爷爷家就有一只小狗，一只肥头大耳的狗。'
  },
  WordUse: {
    docSentence:
      '我最喜欢的动物是小狗。因为它活蹦乱跳的非常可爱。越加就有一只小狗。一只肥头大耳的勾。他每次现在我都会摇着尾巴非常开心地向我走来。并且呢它为王汪汪地叫。然后再跟我打招呼一样。我喜欢摸它。它的毛。那么三。嗯怎么说又软又温暖。答有一点四X。特别厉害。就是它那个它它。它它可以结着我。中的小球。嗯可太棒了嗯。嗯我希望我的妈妈也可以进位小一只就是像爷爷在这样的？那么非常可爱的小狗。这样我可以每天不管是早上。还是晚上都能和他在一起了。因为我这个太喜欢小狗了。就是。',
    docTotalWord: 69,
    docWords: {
      动词: {
        count: 19,
        word: [
          '开心',
          '会',
          '答有',
          '可',
          '希望',
          '走来',
          '摇',
          '摸',
          '打招呼',
          '进位',
          '结',
          '喜欢',
          '说',
          '像',
          '叫',
          '可爱',
          '能',
          '有',
          '是'
        ]
      },
      时间修饰词: {
        count: 3,
        word: ['晚上', '现在', '早上']
      },
      修饰词: {
        count: 4,
        word: ['温暖', '软', '厉害', '小']
      },
      代名词: {
        count: 11,
        word: [
          '这样',
          '他',
          '那个',
          '每天',
          '它',
          '我',
          '这个',
          '一样',
          '怎么',
          '那么',
          '每次'
        ]
      },
      名词: {
        count: 12,
        word: [
          '太棒了',
          '尾巴',
          '毛',
          '爷爷',
          '汪汪',
          '地向',
          '小球',
          '勾',
          '妈妈',
          '小狗',
          '动物',
          '王'
        ]
      },
      并列连词: {
        count: 7,
        word: ['然后', '和', '可以', '并且', '还是', '不管', '因为']
      },
      副词: {
        count: 11,
        word: [
          '非常',
          '就',
          '就是',
          '特别',
          '太',
          '越加',
          '又',
          '也',
          '最',
          '再',
          '都'
        ]
      },
      介词: {
        count: 0,
        word: []
      },
      其他: {
        count: 18,
        word: [
          '跟',
          '一只',
          '嗯',
          '为',
          'X',
          '中',
          '呢',
          '。',
          '地',
          '在',
          '三',
          '了',
          '一起',
          '四',
          '的',
          '一点',
          '？',
          '着'
        ]
      },
      成语: {
        count: 2,
        word: ['肥头大耳', '活蹦乱跳']
      }
    },
    docDetail: [
      {
        index: 1,
        sentence: '我最喜欢的动物是小狗。',
        totalWords: 6
      },
      {
        index: 2,
        sentence: '因为它活蹦乱跳的非常可爱。',
        totalWords: 5
      },
      {
        index: 3,
        sentence: '越加就有一只小狗。',
        totalWords: 4
      },
      {
        index: 4,
        sentence: '一只肥头大耳的勾。',
        totalWords: 2
      },
      {
        index: 5,
        sentence: '他每次现在我都会摇着尾巴非常开心地向我走来。',
        totalWords: 12
      },
      {
        index: 6,
        sentence: '并且呢它为王汪汪地叫。',
        totalWords: 5
      },
      {
        index: 7,
        sentence: '然后再跟我打招呼一样。',
        totalWords: 5
      },
      {
        index: 8,
        sentence: '我喜欢摸它。',
        totalWords: 4
      },
      {
        index: 9,
        sentence: '它的毛。',
        totalWords: 2
      },
      {
        index: 10,
        sentence: '那么三。',
        totalWords: 1
      },
      {
        index: 11,
        sentence: '嗯怎么说又软又温暖。',
        totalWords: 5
      },
      {
        index: 12,
        sentence: '答有一点四X。',
        totalWords: 1
      },
      {
        index: 13,
        sentence: '特别厉害。',
        totalWords: 2
      },
      {
        index: 14,
        sentence: '就是它那个它它。',
        totalWords: 3
      },
      {
        index: 15,
        sentence: '它它可以结着我。',
        totalWords: 4
      },
      {
        index: 16,
        sentence: '中的小球。',
        totalWords: 1
      },
      {
        index: 17,
        sentence: '嗯可太棒了嗯。',
        totalWords: 2
      },
      {
        index: 18,
        sentence: '嗯我希望我的妈妈也可以进位小一只就是像爷爷在这样的？',
        totalWords: 11
      },
      {
        index: 19,
        sentence: '那么非常可爱的小狗。',
        totalWords: 4
      },
      {
        index: 20,
        sentence: '这样我可以每天不管是早上。',
        totalWords: 7
      },
      {
        index: 21,
        sentence: '还是晚上都能和他在一起了。',
        totalWords: 6
      },
      {
        index: 22,
        sentence: '因为我这个太喜欢小狗了。',
        totalWords: 6
      },
      {
        index: 23,
        sentence: '就是。',
        totalWords: 1
      }
    ]
  },
  subject: '',
  audioBlobUrl: '/static/audio/my-love-animal.m4a'
};

const reducerDolphin = (state = reduxDolphinInitialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_DOLPHIN_LONGSENT:
      return {
        ...state,
        LongSent: action.data
      };
    // DOLPHIN 相关
    case actionTypes.UPDATE_DOLPHIN_SUBJECT:
      return {
        ...state,
        subject: action.data
      };
    case actionTypes.UPDATE_DOLPHIN_AUDIOBLOBURL:
      return {
        ...state,
        audioBlobUrl: action.data
      };
    case actionTypes.UPDATE_DOLPHIN:
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
};

export default reducerDolphin;
