import { combineReducers } from 'redux';
import reducerClassfx from './reducer-classfx';
import reducerDolphin from './reducer-dolphin';
import reducerEnCorrect from './reducer-enCorrect';

const reducer = combineReducers({
  classfx: reducerClassfx,
  dolphin: reducerDolphin,
  enCorrect: reducerEnCorrect
});

export default reducer;
