/**
 * apis.js and methods.js contain some basic functions.
 * Properties in this file are somehow higher-level functions.
 * Notice that functions in this file may have same name as in
 * apis.js or methods.js, and therefore override the function
 * in apis.js or methods.js. It is highly recommended that using
 * the most exposed function.
 *
 * domain_bridge_token_* always hold the most fresh login token.
 * @zhaoxuxu @2019-12-31
 */

import apis from './apis';
import methods from './methods';

export default {
  ...apis,
  ...methods,
  consoleLogout() {
    if (methods.hasLoggedin()) {
      return apis.consoleLogout().then(() => {
        methods.clearUserAuth();
        return true;
      });
    }
    return new Promise((resolve) => {
      methods.clearUserAuth();
      resolve(true);
    });
  }
};
