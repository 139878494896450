export default {
  loginBrandTop: 'The LLM developed by TAL Education Group,',
  loginBrandBottom:
    'which focuses on math related problem solving and lecturing',
  welcome: 'Welcome to MathGPT, please log in',
  inputPhone: 'Please enter your phone number',
  validPhone: 'Invalid phone number',
  lang: 'en',
  loginTitle: 'Welcome to MathGPT, please log in',
  placeholderPhone: 'Enter phone number',
  placeholderCode: 'Enter SMS code',
  getCodeBtn: 'Send code',
  checkPhone: 'Invalid phone number',
  checkCode: 'Invalid verification code',
  checkPolicy: 'Please check the box below for the agreement',
  loginBtn: 'Log in',
  agreement1: 'I agree to',
  agreement2: ' the Terms of Service',
  agreement3: ' and',
  agreement4: ' Privacy Policy',
  applyBrandContent:
    'Please fill out the registration application form in detail. This will help expedite your experience application. The information you provide will be used for the review of your application. Ensure your contact information is accurate and valid, so our staff can reach you when necessary.',
  applyProgress1: 'Sign up',
  applyProgress2: 'Apply',
  applyProgress3: 'Review',
  applyProgress4: 'Completed',
  applyTitle: 'Join the MathGPT waitlist',
  applyToast:
    'Your information will be used for account registration and review of your application.',
  changeType: 'Type',
  changePurpose: 'Purpose',
  type1: 'Individual',
  type2: 'Company',
  type1Purpose1: 'Math problem solving',
  type1Purpose2: 'Math tutoring',
  type1Purpose3: 'Math for Interest',
  type2Purpose1: 'Personalized teaching support',
  type2Purpose2: 'Customized application development',
  type2Purpose3: 'Complex problem solving',
  commonPurpose: 'Others',
  placeholderName: 'Please enter your name',
  placeholderCompany: 'Please enter your company nam',
  placeholderPosition: 'Please enter your position',
  placeholderEmail: 'Please enter your email address',
  applyBtn: 'Apply',
  checkEmail: 'Invalid email format',
  checkApplyName: 'Chinese and English only, up to 14 characters',
  checkCommonName: 'Chinese and English only, up to 40 characters',
  auditTitle1: 'You have been added to the waitlist. ',
  auditTitle2:
    'If you are successfully enrolled from the waitlist, you will receive a text message.',
  auditFooter1: 'Learn more about MathGPT，',
  auditFooter2: 'return to homepage',
  homeBtn: 'New chat',
  homeDateToday: 'Today',
  homeDateYesterday: 'Yesterday',
  homePrevious7: 'Previous 7 days',
  homePrevious30: 'Previous 30 days',
  month: 'Month',
  year: 'Year',
  footerSetting: 'Settings',
  footerOut: 'Log out',
  modalAccount: 'Account',
  modalUserDelete: 'Delete account',
  modalDeleteText:
    'If you no longer use this account, you have the option to delete it. Upon successful deletion, all associated services, data, and privacy information will be permanently erased and cannot be recovered.',
  modalDeleteBtn: 'Delete account',
  verifyModalTitle: 'Verify your identity',
  verifyModalText1: 'To ensure that the account',
  verifyModalText2: 'is being operated by you, ',
  verifyModalText3: 'please verify your mobile phone number',
  verifyModalBtn1: 'Cancel',
  verifyModalBtn2: 'Verify',
  toVerifyModalTitle: 'Are you sure you want to delete your account?',
  toVerifyModalText:
    'Upon successful deletion, all associated services, data, and privacy information will be permanently erased and cannot be recovered.',
  toVerifyModalBtn: 'Continue',
  quitModalTitle: 'prompt',
  quitModalText: 'Are you sure you want to log out ？',
  primaryExample: 'Primary School Math',
  middleExample: 'Middle School Math',
  highExample: 'High School Math',
  quesOrigin: 'quesOriginEn',
  ques: 'quesEn',
  editorInput: 'Enter a math problem Press Shift +Enter for a new line',
  editorEquations: 'Create and edit math equations',
  editorImg: 'Please upload photo with numbers or text',
  termsService: 'Terms of Service',
  privacyPolicy: 'Privacy policy',
  feedback: 'Feedback',
  toastLogin: 'Login successfully',
  toastLogOut: 'Log out successfully',
  toastDelete: 'Account deleted successfully',
  toastCreate: 'Create successfully',
  toastApply: 'Apply successfully',
  toastDel: 'Deleted successfully',
  toastUpdate: 'Updated successfully',
  toastCopy: 'Copied',
  toastLike: 'Thumbs up！',
  toastStepOn: 'Thumbs down！',
  toastBack: 'Feedback successfully',
  feedbackTitle: 'Feedback',
  feedbackBtn: 'Confirm',
  feedbackText:
    'Please enter your feedback. If you have better content, you can let us know.',
  startChat: 'New chat，',
  startChatEnd: 'change topic',
  ban24:
    'I am a mathematical AI model, and I can only provide answers related to mathematics at the moment,Please ask me again after 24 hours.',
  banForever:
    'I am a mathematical AI model, and I can only provide answers related to mathematics at the moment,If you need to continue using, please contact our customer service:MathGPT@tal.com.',
  violationText: 'I\'m sorry, I haven\'t learned about this topic yet, so I can\'t provide relevant information. You can choose another question, and I will do my best to assist you.',
  imgUpload: 'Please upload image in required format：',
  imgErrorSize: 'Image size is incompatible',
  imgErrorSize2: 'Maximum image size for upload is 2MB',
  knowledge: 'Knowledge',
  goodLuck: 'Random',
  luckErrorToast:
    'A small issue occurred, unable to fetch questions,please try again later',
  primary: 'Primary School',
  middle: 'Middle School',
  high: 'High School',
  popoverListItemQueTag: 'Q',
  mathanswering: ' answering',
  // eslint-disable-next-line quotes
  related:
    'I am a mathematical AI model, and I can only provide answers related to mathematics',
  chatException: 'The conversation is busy, please try again later.',
  timeout: 'Login is busy, please try again later.',
  loginExpired: 'login expired',
  forbiddon403: '403 forbiddon',
  notFound: '404 not found',
  serviceException: '500 Server exception, please try again later.',
  requestErr: '502 Request failed, please try again later.',
  otherErr: 'Server exception, please try again later.',
  official: 'english',
  longToken: 'Sorry, your input is too long',
  system: 'System is busy, please try again later',
  nextSession:
    'Please wait until the previous session has ended before sending the next one',
  overSend: 'Please send after the previous session has ended',
  errorLatex:
    // eslint-disable-next-line quotes
    "There's incorrect LaTeX content in the dialog box. Would you like to continue sending",
  noFindText: 'Text not found',
  imgNoIdentify: 'Image cannot be recognized, please upload again',
  ocrText: 'OCR processing...',
  imgPreview: 'Image preview',
  invalidNet: 'Network unavailable',
  searchText: 'Query cannot be empty',
  inputWarning: 'Input cannot be empty',
  remind: 'Reminder',
  latexErr:
    // eslint-disable-next-line quotes
    "Formula rendering seems to have encountered an issue. We're working to fix it",
  evaluate: 'Evaluate success',
  analysis: '[Analysis]',
  detail: '[Explanation]',
  point: '[Insight]',
  hasNewchat: 'this is a new chat',
  compareAnswer: 'This response compared to the previous one',
  better: 'Better',
  worse: 'Worse',
  almost: 'Same',
  regenerate: 'Regenerate',
  stopGenerate: 'Stop',
  noRecommend: 'No recommend',
  formula: 'Formula',
  current130B: 'current model：130B',
  pleaseSay: 'Please state your problem',
  pleaseSay2: 'Please speak up...',
  unableRecord: 'I\'m sorry, I cannot record your voice',
  noMicrophone: 'There is no available microphone',
  soundToolow: 'Your voice is too low, please speak up and state your content～',
  recorderToast: 'Recording exceeded maximum duration: 1 minute. Analyzing now...'
};
